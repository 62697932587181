import { useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import { useCheckoutState, useCheckoutDispatch } from "@hooks/useCheckout";
import { handleize } from "@services/format/string";
import { resolveColours } from "../lib/resolve-colours";
import formatVariantPrice from "@components/Product/lib/format-variant-price";
import { useCurrencyState } from "@hooks/useCurrency";

export const useQuickAdd = (product, setColour) => {
  const router = useRouter();
  const { cartIsLoading } = useCheckoutState();
  const { addCartItem } = useCheckoutDispatch();
  const [showQuickAdd, setShowQuickAdd] = useState(false);
  const [selectedColour, setSelectedColour] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [query, setQuery] = useState(null);
  const { currency } = useCurrencyState();
  const isFrench = router.locale === "fr";

  useEffect(() => {
    initialColourSelection();
    return () => setSelectedColour(null);
  }, [setColour]);

  useEffect(() => {
    if (selectedColour && selectedSize) {
      addToCart();
      setSelectedSize(null);
    }
  }, [selectedColour, selectedSize]);

  useEffect(() => {
    if (product && product.url && selectedColour) {
      setQuery({
        slug: product.url.slug,
        colour: encodeURIComponent(handleize(selectedColour.title)),
      });
    }
  }, [selectedColour]);

  const toggleQuickAdd = () => setShowQuickAdd(!showQuickAdd);

  const initialColourSelection = useCallback(() => {
    const colours = resolveColours(product);
    let colourPicked = false;

    colours?.map((colour) => {
      const { hex, handle, id, tags } = colour;
      const title = isFrench && colour.titleFr ? colour.titleFr : colour.title;

      const hasTag = tags.find((colour) => colour.toLowerCase() === setColour);
      if (hasTag) {
        setSelectedColour({ hex, handle, id, title, tags });
        colourPicked = true;
      } else if (id === product.id && !colourPicked) {
        setSelectedColour({ hex, handle, id, title, tags });
      }
    });
  });

  const getQuickAddProduct = useCallback(() => {
    const quickAddProduct = product.sanity?.shopifyProducts.filter(
      (p) => p.id === selectedColour?.id
    )[0];

    const { variants } = quickAddProduct;

    const getSize = (selectedoptions) =>
      selectedoptions.filter((option) => option.value === selectedSize)[0];

    const variant = variants.filter((variant) => {
      const size = getSize(variant.selectedOptions);
      return size && size.value === selectedSize;
    })[0];

    return {
      variant,
      productPage: product.sanity,
    };
  });

  const addToCart = useCallback(() => {
    const { variant, productPage } = getQuickAddProduct();

    let { price, compareAtPrice } = formatVariantPrice(variant, currency);

    // Remove the currency symbol (and any other non-numeric characters) from the price
    price = price.replace(/[^\d.]/g, "");

    variant.priceV2 = {
      amount: Number(price),
      currencyCode: currency,
    };

    addCartItem(variant, 1, productPage);
  });

  return {
    showQuickAdd,
    toggleQuickAdd,
    selectedColour,
    setSelectedColour,
    selectedSize,
    setSelectedSize,
    cartIsLoading,
    query,
  };
};
